export default () => {
    const script = document.createElement('script')
    script.src = 'https://cdn.websitepolicies.io/lib/cookieconsent/1.0.3/cookieconsent.min.js'
    script.text = ``
    script.defer = true
    document.getElementsByTagName('head')[0].appendChild(script);

    const script2 = document.createElement('script') 
    script2.text = ` window.addEventListener("load", function () {
      window.wpcc.init({
        "border": "thin",
        "corners": "small",
        "colors": {
          "popup": {
            "background": "#0F214F",
            "text": "#FFFFFF",
            "border": "#5e65c2"
          },
          "button": {
            "background": "#228B22",
            "text": "#ffffff"
          }
        },
        "content": {
          "href": "https://checkmot.com/cookies",
          "message": "We use cookies on our website to provide you with a better browsing experience. Carry on browsing if you're happy with this and you can see our Cookies Policy",
          "link": "here.",
          "button": "Allow"
        },
        "position": "bottom"
      })
    });`
    document.getElementsByTagName('head')[0].appendChild(script2);
  };
