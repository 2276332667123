// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/uknumberplate/uknumberplate-webfont.woff");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:'uknumberplate';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:normal;font-style:normal;font-display:swap}.mot-input[data-v-c848af0a]{background-color:#fff;max-width:450px}@media (max-width: 575px){.mot-input[data-v-c848af0a]{max-width:100%}}.mot-input .input-group-prepend img[data-v-c848af0a]{height:74px;width:46px;z-index:2}.mot-input .form-control[data-v-c848af0a]{height:74px;font-family:\"uknumberplate\";font-size:2.1rem;letter-spacing:2px;border:none}.mot-input .form-control[data-v-c848af0a]:focus{outline:none;box-shadow:none;z-index:0 !important}@media (max-width: 575px){.mot-input .form-control[data-v-c848af0a]{line-height:inherit;font-size:2rem;text-transform:uppercase;letter-spacing:none}}.mot-input .input-group-append[data-v-c848af0a]{padding-right:15px}.mot-input .input-group-append button[data-v-c848af0a]{border-color:transparent;border-radius:0px !important;width:169px;height:50px;margin-top:13px;z-index:3}.mot-input .input-group-append button[data-v-c848af0a]:disabled{cursor:not-allowed;background:#e2e2e2;color:grey}@media (max-width: 1199px){.mot-input .input-group-append button[data-v-c848af0a]{width:auto}}@media (max-width: 575px){.mot-input .input-group-append button[data-v-c848af0a]{width:auto;font-style:normal;font-weight:900;line-height:16px;font-size:13px;text-align:center;color:#ffffff}}\n", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
