
export default {
  components: {
  },
  data() {
    return {
      scrolled: false,
    }
  },
  created() {
    if (process.browser)
      window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 0;
    },
  },
}
